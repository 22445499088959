/*global $*/
/*eslint no-undef: "error"*/
export default class GalleryTab {
    classItem = 'gallery-tab--item'
    classButton = 'gallery-tab--button'
    classBlock = 'gallery-tab--block'
    classButtonActive = 'gallery-tab__tab---active'
    classBlockActive = 'gallery-tab__block---active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data(`block`)

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}`).removeClass(self.classBlockActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classBlock}[data-block='${block}']`).addClass(self.classBlockActive)
        })
    }
}
