/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createService () {
        return new Swiper('#slider--service', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--service-next',
                prevEl: '.slider--service-prev',
            },
            pagination: {
                el: '.swiper-pagination--service',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createPortfolio () {
        return new Swiper('#slider--portfolio', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--portfolio-next',
                prevEl: '.slider--portfolio-prev',
            },
            pagination: {
                el: '.swiper-pagination--portfolio',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createPortfolioTwo () {
        return new Swiper('#slider--portfolio-two', {
            slidesPerView: 20,
            spaceBetween: 2,
            // loop: true,
            navigation: {
                nextEl: '.slider--portfolio-two-next',
                prevEl: '.slider--portfolio-two-prev',
            },
            pagination: {
                el: '.swiper-pagination--portfolio-two',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                }
            }
        })
    }
    createSert () {
        return new Swiper('#slider--sert', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--sert-next',
                prevEl: '.slider--sert-prev',
            },
            pagination: {
                el: '.swiper-pagination--sert',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 4,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }
    createPartner () {
        return new Swiper('#slider--partner', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--partner-next',
                prevEl: '.slider--partner-prev',
            },
            pagination: {
                el: '.swiper-pagination--partner',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }
    createGallery () {
        return new Swiper('#slider--gallery', {
            slidesPerView: 20,
            spaceBetween: 3,
            // loop: true,
            navigation: {
                nextEl: '.slider--gallery-next',
                prevEl: '.slider--gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                }
            }
        })
    }
    createHistory () {
        return new Swiper('#slider--history', {
            slidesPerView: 0,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--history-next',
                prevEl: '.slider--history-prev',
            },
            pagination: {
                el: '.swiper-pagination--history',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                }
            }
        })
    }
    createWork () {
        return new Swiper('#slider--work', {
            slidesPerView: 20,
            spaceBetween: 4,
            // loop: true,
            navigation: {
                nextEl: '.slider--work-next',
                prevEl: '.slider--work-prev',
            },
            pagination: {
                el: '.swiper-pagination--work',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 8,
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 8
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                991: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1024: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--service').length) > 0) {
            this.createService()
        }

        if (parseInt($('#slider--portfolio').length) > 0) {
            this.createPortfolio()
        }

        if (parseInt($('#slider--portfolio-two').length) > 0) {
            this.createPortfolioTwo()
        }

        if (parseInt($('#slider--sert').length) > 0) {
            this.createSert()
        }

        if (parseInt($('#slider--partner').length) > 0) {
            this.createPartner()
        }

        if (parseInt($('#slider--gallery').length) > 0) {
            this.createGallery()
        }

        if (parseInt($('#slider--history').length) > 0) {
            this.createHistory()
        }

        if (parseInt($('#slider--work').length) > 0) {
            this.createWork()
        }
    }
}
