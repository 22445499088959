/*global $*/
/*eslint no-undef: "error"*/
export default class Review {
    classItem = 'review--item'
    classButton = 'review--button'
    classItemAll = 'review-list---all'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).addClass(self.classItemAll)
        })
    }
}
