<template lang="pug">
.form.form---order
  .form__fields
    .form__field
      label.input-text.input-text---gray(:class="{'input-text---error': fieldError}")
        input.input-text__input(
          type="text"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )

    .form__field
      label.input-textarea.input-textarea---height.input-textarea---gray
        textarea.input-textarea__input(
          :placeholder="formFieldTextPlaceholder"
          v-model="fieldText"
        )

    .form__field
      label.input-text.input-text---gray(:class="{'input-text---error': fieldError}")
        input.input-text__input(
          type="tel"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

    .form__field
      label.input-text.input-text---gray(:class="{'input-text---error': fieldError}")
        input.input-text__input(
          type="email"
          :placeholder="formFieldEmailPlaceholder"
          v-model="fieldEmail"
        )

  .form__file
    label.form-files
      input.form-files__input(
        type="file"
        name="files"
        ref="files"
        multiple
        style="display: none;"
        @change="onFileChange"
      )

      svg.form-files__icon
        use(xlink:href="#icon-file")

      .form-files__button Прикрепить файл

      .form-files__info(v-html="fileResult")

  .form__bottom
    .form__button
      .button.button---width.button---orange(@click="getSend()")
        .button__wrapper
          .button__text(v-html="formButtonName")

    .form__consent
      label.consent.consent---black
        input.consent__input(type="checkbox" value="consent" checked="checked" v-model="consent")
        span.consent__label(v-html="formConsent")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPopupOrder',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldEmail",
    "formFieldEmailPlaceholder",
    "formFieldText",
    "formFieldTextPlaceholder",
    "formFieldPage",

    "formButtonName",

    "formConsent",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldText: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false,

      files: '',
      formFieldFilesButton: 'Выберите файлы',
      fileResult: '(Файл не прикреплен)'
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    onFileChange () {
      this.files = this.$refs.files.files

      if (this.files.length === 1) {
        this.fileResult = 'Добавлен ' + this.files.length + ' файл'
      } else if ((this.files.length === 2) || (this.files.length === 3) || (this.files.length === 4)) {
        this.fileResult = 'Добавлено ' + this.files.length + ' файла'
      } else {
        this.fileResult = 'Добавлено ' + this.files.length + ' файлов'
      }
    },

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldText,
              value: this.fieldText
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files,
          redirectPage: this.formRedirect
        })

        this.fieldName = this.fieldPhone = this.fieldEmail = this.fieldText = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100)
      },
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      fieldEmail: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(100)
      },
      consent: {
        required
      }
    }
  }
}
</script>
