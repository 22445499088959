/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import './assets/js/css/ImportCss'
import { Fancybox } from '@fancyapps/ui'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import AppFormPopupPhone from '@/components/Form/AppFormPopupPhone.vue'
import AppFormPopupPrice from '@/components/Form/AppFormPopupPrice.vue'
import AppFormPopupOrder from '@/components/Form/AppFormPopupOrder.vue'
import AppFormBottom from '@/components/Form/AppFormBottom.vue'
import AppFormCalc from '@/components/Form/AppFormCalc.vue'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import Open from '@/assets/js/Open/Open'
import TextShow from '@/assets/js/Text/Show/TextShow'
import Faq from '@/assets/js/Faq/Faq'
import FaqMobile from '@/assets/js/Faq/Mobile/FaqMobile'
import ContactBlocks from '@/assets/js/Contact/Blocks/ContactBlocks'
import Review from '@/assets/js/Review/Review'
import PriceTable from '@/assets/js/Price/Table/PriceTable'
import GalleryTab from '@/assets/js/Gallery/Tab/GalleryTab'

// Подключаем глобально jQuery
const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'fancybox--popup'
    })

    Fancybox.bind('[data-fancybox-form]', {
        mainClass: 'fancybox---form'
    })

    new SwiperSlider()

    if(document.getElementById('form--phone') != null) {
        createApp({})
            .component('form-phone', AppFormPopupPhone)
            .use(Maska)
            .use(form)
            .mount('#form--phone')
    }

    if(document.getElementById('form--price') != null) {
        createApp({})
            .component('form-price', AppFormPopupPrice)
            .use(Maska)
            .use(form)
            .mount('#form--price')
    }

    if(document.getElementById('form--order') != null) {
        createApp({})
            .component('form-order', AppFormPopupOrder)
            .use(Maska)
            .use(form)
            .mount('#form--order')
    }

    if(document.getElementById('form--bottom') != null) {
        createApp({})
            .component('form-bottom', AppFormBottom)
            .use(Maska)
            .use(form)
            .mount('#form--bottom')
    }

    if(document.getElementById('form--calc') != null) {
        createApp({})
            .component('form-calc', AppFormCalc)
            .use(Maska)
            .use(form)
            .mount('#form--calc')
    }

    new HeaderMobile()
    new MenuMobile()
    new Open()
    new TextShow()
    new Faq()
    new FaqMobile()
    new ContactBlocks()
    new Review()
    new PriceTable()
    new GalleryTab()
})
