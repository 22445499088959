/*global $*/
/*eslint no-undef: "error"*/
export default class FaqMobile {
    classItem = 'faq-mobile--item'
    classButton = 'faq-mobile--button'
    classItemOpen = 'faq-mobile__item---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            console.log('work')
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
