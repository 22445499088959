/*global $*/
/*eslint no-undef: "error"*/
export default class PriceTable {
    classItem = 'price-table--item'
    classTabs = 'price-table--tabs'
    classTab = 'price-table--tab'
    classTables = 'price-table--tables'
    classTable = 'price-table--table'

    classTabActive = 'price-table__tab-active'
    classTableActive = 'price-table__table-active'

    constructor () {
        let self = this

        $(`.${self.classTab}`).click(function () {
            let block = $(this).data('block')

            $(this).closest(`.${self.classItem}`).find(`.${self.classTab}`).removeClass(self.classTabActive)
            $(this).addClass(self.classTabActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classTable}`).removeClass(self.classTableActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classTable}[data-block='${block}']`).addClass(self.classTableActive)
        })
    }
}
