/*global $*/
/*eslint no-undef: "error"*/
export default class Faq {
    classItem = 'faq--item'
    classButton = 'faq--button'
    classButtonActive = 'faq__button---active'
    classText = 'faq--text'
    classTextActive = 'faq__text---active'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data(`block`)

            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}`).removeClass(self.classButtonActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classButton}[data-block='${block}']`).addClass(self.classButtonActive)

            $(this).closest(`.${self.classItem}`).find(`.${self.classText}`).removeClass(self.classTextActive)
            $(this).closest(`.${self.classItem}`).find(`.${self.classText}[data-block='${block}']`).addClass(self.classTextActive)
        })
    }
}
