/*global $*/
/*eslint no-undef: "error"*/
export default class TextShow {
    classItem = 'text-show--item'
    classButton = 'text-show--button'
    classItemOpen = 'text-show---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
