/*global $*/
/*eslint no-undef: "error"*/
export default class ContactBlocks {
    classItem = 'contact-blocks--item'
    classButton = 'contact-blocks--button'
    classItemOpen = 'contact-blocks---open'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            $(this).closest(`.${self.classItem}`).toggleClass(self.classItemOpen)
        })
    }
}
