<template lang="pug">
.form.form---phone
  .form__fields
    .form__field
      label.input-text.input-text---gray
        input.input-text__input(
          type="text"
          :placeholder="formFieldNamePlaceholder"
          v-model="fieldName"
        )

    .form__field
      label.input-text.input-text---gray(:class="{'input-text---error': fieldError}")
        input.input-text__input(
          type="tel"
          :placeholder="formFieldPhonePlaceholder"
          v-model="fieldPhone"
          v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']"
        )

  .form__bottom
    .form__button
      .button.button---width.button---orange(@click="getSend()")
        .button__wrapper
          .button__text(v-html="formButtonName")

    .form__consent
      label.consent.consent---black
        input.consent__input(type="checkbox" value="consent" checked="checked" v-model="consent")
        span.consent__label(v-html="formConsent")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'
export default {
  name: 'AppFormPopupPhone',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingTitle",
    "formSettingSubject",

    "formFieldName",
    "formFieldNamePlaceholder",
    "formFieldPhone",
    "formFieldPhonePlaceholder",
    "formFieldPage",

    "formButtonName",

    "formConsent",

    "formRedirect",
    "formPageId",
    "formPagePagetitle"
  ],

  data () {
    return {
      fieldName: '',
      fieldPhone: '',
      files: '',
      consent: ['consent'],
      sendForm: false,
      fieldError: false
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (this.v$.$invalid) {
        this.fieldError = true
      }

      if (!this.v$.$invalid) {
        this.sendForm = !this.sendForm

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldPage,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            },
          ],
          files: this.files,
          redirectPage: this.formRedirect
        })

        this.fieldName = this.fieldPhone = ''

        setTimeout(() => {
          this.sendForm = !this.sendForm
        }, 3000)
      }
    }
  },

  validations () {
    return {
      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },
      consent: {
        required
      }
    }
  }
}
</script>
